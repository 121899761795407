import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Import Theme`}</h2>
    <p>{`Legion iOS have 5 Themes`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/content/themelegion.png",
            "alt": "steps installation"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeAGR
import ThemeEazy
import ThemeIHS
import LegionUI
import ThemeMyTEnS
`}</code></pre>
    <div className="divi" />
    <h2>{`Step`}</h2>
    <ol>
      <li parentName="ol">{`Open App page`}</li>
      <li parentName="ol">{`Import your THEME. example : `}<inlineCode parentName="li">{`import LegionUI`}</inlineCode></li>
      <li parentName="ol">{`Import FirebaseCore`}</li>
      <li parentName="ol">{`Add the following code snippet in the root page of project`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import SwiftUI
import LegionUI

@main
struct LegionPrototypeApp: App {
    @UIApplicationDelegateAdaptor(AppDelegate.self) var delegate
    var body: some Scene {
        WindowGroup {
            ContentView()
        }
    }
}
class AppDelegate: NSObject, UIApplicationDelegate {
    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey : Any]? = nil) -> Bool {
      LGN.configure()
    return true
  }
}
`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Open ContentView`}</li>
      <li parentName="ol">{`Import your THEME. example : `}<inlineCode parentName="li">{`import LegionUI`}</inlineCode></li>
      <li parentName="ol">{`Add the following code snippet in the view page of project`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import SwiftUI
import LegionUI

struct ContentView: View {
    var body: some View {
        LGNSolidButton(title: "title")
    }
}

struct ContentView_Previews: PreviewProvider {
    static var previews: some View {
        ContentView()
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      